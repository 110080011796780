<template>
  <div class="content-wrapper site-content-wrapper clearfix">
    <div id="primary" class="site-content" role="main">
      <div id="rise-player" class="is-block"></div>
      <div id="div-gpt-ad-1634890953983-0" class="horizontal-placeholder"></div>
      <div id="tips-articles-wrap">
        <div class="app-teaser-wrapper">
          <div class="app-teaser clearfix">
            <img
              class="app-icon"
              srcset="../assets/logo.png 1x, ../assets/logo.png 2x"
              src="../assets/logo.png"
              alt="sudoku app icon"
            />
            <div class="app-info">
              <div class="app-info-heading">数独 - 数字游戏</div>
              <div class="app-info-author">Holine Online</div>
              <div class="app-info-rating">
                <i class="icon icon-star"></i><i class="icon icon-star"></i
                ><i class="icon icon-star"></i><i class="icon icon-star"></i
                ><i class="icon icon-star-half"></i>
              </div>
            </div>
          </div>
          <div class="app-teaser-buttons">
            <a
              href="javascript:void(0);"
              title="下载应用，请到 Google Play"
              class="app-teaser-button button-google-play"
              ><div class="google-play-badge"></div></a
            ><a
              href="javascript:void(0);"
              title="App Store 下载"
              class="app-teaser-button button-app-store"
              ><div class="app-store-badge"></div
            ></a>
          </div>
        </div>
        <div class="article-wrapper">
          <div class="post">
            <h1 class="entry-title">立即玩免费的数独游戏！</h1>
            <div class="entry-content">
              <p>
                数独游戏是有史以来最受欢迎的益智游戏之一。
                数独游戏的目标是用数字填充9x9的宫格，让每一行，每一列和每个3x3小九宫部分都包含1到9之间的所有数字。数独作为逻辑谜题，也是一款出色的智力游戏。如果你每天都玩数独游戏，很快就会开始注意到你的注意力和整体智力有所提高。立即开始游戏。用不了多久，数独就会成为你最喜欢的免费线上游戏。
              </p>
            </div>
          </div>
          <div class="post">
            <h2 class="entry-title">关于数独游戏</h2>
            <div class="entry-content">
              <p>
                风靡日本的益智游戏“数独”是基于数字的逻辑位置的一款游戏。数独作为一款在线的逻辑游戏，不需要你具备计算和特殊的数学技能；游戏中所需要的只是智慧和专注。
              </p>
            </div>
          </div>
          <div class="post">
            <h2 class="entry-title">如何玩数独</h2>
            <div class="entry-content">
              <p>
                数独游戏的目标是用数字填充9x9的宫格，让每一列，每一行和每个3x3小九宫部分都包含1到9之间的数字。在游戏开始时，9x9的宫格中会有一些方格已填上数字。你要做的是运用逻辑来填上缺失的数字并完成宫格。不要忘记，如果出现以下情况，表示填法不正确：
              </p>
              <ul>
                <li>任意一行中，有多个相同的1到9中的数字</li>
                <li>任意一列中，有多个相同的1到9中的数字</li>
                <li>任意一个3x3小宫格中，有多个相同的1到9中的数字</li>
              </ul>
            </div>
          </div>
          <div class="post">
            <h3 class="entry-title">数独技巧</h3>
            <div class="entry-content">
              <p>
                一旦你掌握了小窍门，数独就会成为一款有趣的益智游戏。然而，学习玩数独可能会让新手有些胆怯。所以，如果你是一个完全的新手，这里有一些数独小窍门可以用来提高你的数独游戏技巧。
              </p>
              <ul>
                <li>
                  技巧1：寻找3x3小宫格的行和列中包含了5个或以上数字的小宫格。试着用未使用的数字填上剩余的空格子。在很多情况下，只要考虑到其他行，列和3x3小宫格中的其他数字，你会发现要填的数字只有一个位置可放。
                </li>
                <li>
                  技巧2：将宫格看作是分开的3列和3行。每一大列包含了3个3×3的小宫格，每一大行也包含了3个3×3的小宫格。现在，寻找具有相同数字的2列或2个宫格。从逻辑上讲，在剩余的9个格子中一定有第三个相同的数字。分别查看其余的9个位置，看看你是否能找到那个缺失数字的位置。
                </li>
              </ul>
              <p>
                现在你对“数独”有了稍微多点的了解，开始玩并享受这个免费线上游戏。
              </p>
            </div>
          </div>
          <div class="post">
            <h4 class="entry-title">关于Holine Online</h4>
            <div class="entry-content">
              <p>
                Holine Online是一个手机开发者和发行商，拥有App Store和Google
                Play上受欢迎的数独应用。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <aside id="aside" class="aside" role="complementary" >
      <div id="div-gpt-ad-1634891177200-0" class="aside-placeholder"></div>
    </aside>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
